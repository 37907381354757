import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String };

  submit() {
    let passprnt_uri = "starpassprnt://v1/print/nopreview?";

    passprnt_uri = passprnt_uri + "back=" + "//";

    passprnt_uri = passprnt_uri + "&url=" + encodeURIComponent(this.urlValue);

    window.location.href = passprnt_uri;

    setTimeout(() => {
      location.reload();
    }, 500);
  }
}
