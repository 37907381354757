import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submitButton"]

  connect() {
    this.submitButtonTarget.disabled = true;
  }

  check() {
    this.submitButtonTarget.disabled = !this.checkboxTarget.checked;
  }

  submitModal(e) {
    e.currentTarget.disabled = true;

    const cartSubmitButton = document.querySelector('button[name="new_order"]');
    const form = document.querySelector('#guest-order form');
    cartSubmitButton.dataset.submitFlag = "true";
    $(this.element).modal('hide');

    form.submit();
  }
}
