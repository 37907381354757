import { Controller } from "stimulus";
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["smsPhoneFiledNew", "smsPhoneFiledCheck", "smsVerificationsNew", "smsVerificationsCheck", "errorExplanationNew", "errorExplanationCheck", "closeButton"];

  connect() {
    const submitButton = document.querySelector('button[name="new_order"]');
    this.modal = new Modal(this.element)

    this.element.addEventListener("ajax:error", (event) => {
      const messages = event.detail[0].map(message => {
        return `<li>${message}</li>`
      }).join("");

      const error_ExplanationTarget = this.isNewModal() ? this.errorExplanationNewTarget : this.errorExplanationCheckTarget;
      error_ExplanationTarget.innerHTML = `
      <div class="alert alert-danger" id="error_explanation" role="alert">
        <ul data-sms-verifications-target="error_explanation">
          ${messages}
        </ul>
      </div>
      `;
    })

    this.element.addEventListener("ajax:success", (event) => {
      if (this.isNewModal()) {
        this.changeModal();
      } else {
        submitButton.dataset.submitFlag = "true";
        this.onEnd();
      }
    });
  }

  newSubmit() {
    this.smsPhoneFiledCheckTarget.value = this.smsPhoneFiledNewTarget.value
  }

  resend() {
    this.smsPhoneFiledNewTarget.value = "";
    this.smsPhoneFiledCheckTarget.value = "";

    this.changeModal();
  }

  isNewModal() {
    return this.smsVerificationsNewTarget.classList.contains("d-display");
  }

  changeModal() {
    this.smsVerificationsNewTarget.classList.toggle('d-none');
    this.smsVerificationsNewTarget.classList.toggle('d-display');

    this.smsVerificationsCheckTarget.classList.toggle('d-display');
    this.smsVerificationsCheckTarget.classList.toggle('d-none');
  }

  openModal() {
    this.modal.show();
  }

  closeModal() {
    this.closeButtonTarget.click();
  }

  getController(event) {
    event.detail.setController((this));
    this.onEnd = event.detail.onEnd;
    return this;
  }
}
