import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["counter", "form", "alartArea", "submit"];
  static values = { clientSlug: String, facilitySlug: String, quantity: Number }

  connect() {
    this.previousNum = this.counterTarget.value;

    this.element.addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });

    // disconnectに書くべきだが、一部の表示が残ってしまうため初期化に毎回実行している
    this.closeModal();

    if ( !this.hasFormTarget ) {    // ターゲット自体がnullの場合javascriptエラーを回避
      return;
    }
    this.formTarget.addEventListener("ajax:success", (event) => {
      this.closeModal();

      if(this.clientSlugValue) {
        $.ajax({
          url: `/clients/${this.clientSlugValue}/cart_type_tab`,
          type: "GET"
        })
        .done(function (data, textStatus, jqXHR) {
          $("#cart-type-tab").html(data);
        })
      }

      let cart_type_nav_param = {}
      if (this.facilitySlugValue) {
        cart_type_nav_param.facility_id = this.facilitySlugValue;
      }

      $.ajax({
        url: "/home/cart_type_nav",
        type: "GET",
        data: cart_type_nav_param,
      })
      .done(function (data, textStatus, jqXHR) {
        $("#cart-type-nav").html(data);
      })
    });

    this.formTarget.addEventListener("ajax:error", (event) => {
      this.closeModal();
    });

  }

  change() {
    let count = this.counterTarget.value

    if(count > this.quantityValue) {
      this.alartAreaTarget.innerHTML = `在庫がありません。<br> 注文可能な商品数は${this.quantityValue}個までです。`;
      this.submitTarget.disabled = true;
    } else if(count <= 0) {
      this.alartAreaTarget.innerHTML = `1以上の数字を入力してください。`;
      this.submitTarget.disabled = true;
    } else {
      this.alartAreaTarget.textContent = null
      this.previousNum = this.counterTarget.value;
      this.submitTarget.disabled = false;
    }
  }

  increment() {
    let count = this.counterTarget.value

    if(count >= this.quantityValue) {
      this.alartAreaTarget.innerHTML = `在庫がありません。<br> 注文可能な商品数は${this.quantityValue}個までです。`;
      this.counterTarget.value = this.previousNum;
    } else if(count < 1) {
      this.alartAreaTarget.innerHTML = null
      this.counterTarget.value = this.previousNum;
    } else {
      this.alartAreaTarget.innerHTML = null
      count++
      this.counterTarget.value = count
      this.previousNum = this.counterTarget.value;
    }
    this.submitTarget.disabled = false;
  }

  decrement() {
    let count = this.counterTarget.value

    count--
    if(count >= this.quantityValue) {
      this.counterTarget.value = this.previousNum;
      this.submitTarget.disabled = false;
      this.alartAreaTarget.textContent = null;
    } else if(0 < count && count < this.quantityValue) {
      this.counterTarget.value = count
      this.previousNum = this.counterTarget.value;
      this.submitTarget.disabled = false;
      if(this.alartAreaTarget.textContent != null) {
        this.alartAreaTarget.textContent = null;
      }
    }
  }

  closeModal() {
    $(this.element).hide().removeAttr('aria-modal').attr('aria-hidden', 'true');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
}
