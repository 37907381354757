import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["orderForm", "willDeliverForm", "paymentForm", "paymentRadioButton", "invoiceTextArea", 'invoiceTextField', 'cardInfo', 'cardNumber', 'orderFormSubmit', 'inputEmail'];

  connect() {
    const getSmsVerificationsControllerEvent = new CustomEvent('getSmsVerificationsController', { detail: {
      setController: (controller)=>{
        this.smsModal=controller;
        },
      onEnd: (ret)=>{
        this.onSmsEnd(ret);
        }
    }});
    window.dispatchEvent(getSmsVerificationsControllerEvent);

    const getDeliveryAddressControllerEvent = new CustomEvent('getDeliveryAddressController', { detail: {
      setController: (controller)=>{
        this.deliveryAddressModal=controller;
        },
      onEnd: (ret)=>{
        this.onDeliveryAddressEnd(ret);
        }
    }});

    window.dispatchEvent(getDeliveryAddressControllerEvent);

    const getCardControllerEvent = new CustomEvent('getCardController', { detail: {
      setController: (controller)=>{
        this.cardModal=controller;
      },
      onEnd: (ret)=> {
        this.onCardEnd(ret);
      }
    }});
    window.dispatchEvent(getCardControllerEvent);

    const paymentButton = this.paymentRadioButtonTargets.find(target => target.checked)
    if (paymentButton) {
      this.checkBox = paymentButton.defaultValue;

      if (this.checkBox == "Guest::Cart::CardPayment" && this.cardInfoTarget.classList.contains('d-none')) {
        this.cardModal.openModal();
      }

      const invoiceRadioButton = this.paymentRadioButtonTargets.find(target => target.defaultValue === "Guest::Cart::InvoicePayment");
      invoiceRadioButton ? this.visibleInvoice(invoiceRadioButton.checked) : this.visibleInvoice(false);
    }

    $('#sms_verifications, #email-modal').on('hidden.bs.modal', this.orderFormSubmitTarget, event => {
      // SMSモーダルのsubmitによって閉じた場合はdisabledを解除せず二重注文を防ぐ
      if(this.orderFormSubmitTarget.dataset.submitFlag !== "true") {
        event.data.disabled = false;
      }
    })
  }

  clickCheckbox(event) {
    this.visibleInvoice(event.target.defaultValue === "Guest::Cart::InvoicePayment");
    this.checkBox = event.target.defaultValue;
    Rails.fire(this.paymentFormTarget, 'submit');
  }

  clickdeliveryAddress(event) {
    this.deliveryAddressModal.delivery_open();
  }

  onSmsEnd() {
    this.smsModal.closeModal();
    this.orderFormTarget.submit();
  }

  onCardEnd(cardRegistered) {
    if (cardRegistered) {
      this.cardInfoTarget.classList.add('d-display');
      this.cardInfoTarget.classList.remove('d-none');

      document.getElementById('flash-block').innerHTML += this.noticeMessage('カードの登録に成功しました。');

      this.checkBox = "Guest::Cart::CardPayment";

      setTimeout(() => {
        $(".alert").alert('close')
      },5000);
    } else {
      // カードが登録されていない時はラジオボタンを外す
      if (this.cardInfoTarget.classList.contains('d-none')) {
        this.paymentRadioButtonTargets.find(target => target.defaultValue === this.checkBox).checked = true;
        this.paymentRadioButtonTargets[0].checked = false;
      }
    }



    Rails.fire(this.paymentFormTarget, 'submit');
  }

  onDeliveryAddressEnd() {

  }

  submit(e) {
    e.currentTarget.disabled = true;
    if (this.paymentRadioButtonTargets.length !== 0 && !this.paymentRadioButtonTargets.find(target => target.checked)) {
      document.getElementById('flash-block').innerHTML += this.alertMessage('支払い方法を設定してください。');

      setTimeout(() => {
        $(".alert").alert('close')
      },5000);

      e.preventDefault();
      e.currentTarget.disabled = false;
      return;
    }

    if (!this.orderFormTarget.reportValidity()) {
      e.preventDefault();
      e.currentTarget.disabled = false;
      return;
    }

    // paymentFormTarget
    $.ajax({
      url: "/cart/update_checkout_type",
      type: 'POST',
      data: $(this.paymentFormTarget).serializeArray(),
      complete: (data) =>  {

        // willDeliverFormTarget
        $.ajax({
          url: "/cart/update_will_deliver_at",
          type: 'POST',
          data: $(this.willDeliverFormTarget).serializeArray(),
          complete: (data) =>  {

            if(this.orderFormSubmitTarget.dataset.smsVerify === "false") {
              // メールアドレス確認モーダルの表示
              this.updateText();
              $("#email-modal").modal('show');
            } else {

              // SMS
              $.ajax({
                url: "cart/check_sms.json",
                dataType: 'json',
                type: 'GET',
                cache: true,
                success: (data) => {
                  this.orderFormTarget.submit();
                },
                error: (XMLHttpRequest, textStatus, errorThrown) =>  {
                  this.smsModal.openModal();
                }
              });
            }
          }
        });
      }
    });
  }

  noticeMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-success">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0"><div class="text-center word-break-word">${message}</div></div>
    </div>
    `
  }

  alertMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-danger">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0 text-center"><div>${message}</div></div>
    </div>
    `
  }

  visibleInvoice(visible) {
    this.invoiceTextFieldTargets.forEach((element) =>
    element.disabled = !visible
    );

    if (visible) {
      this.invoiceTextAreaTarget.classList.remove('d-none');
      this.invoiceTextAreaTarget.classList.add('d-display');
    } else  {
      this.invoiceTextAreaTarget.classList.add('d-none');
      this.invoiceTextAreaTarget.classList.remove('d-display');
    }
  }

  updateText() {
    let email;

    if(this.inputEmailTarget.dataset.login === "true") {
      email = this.inputEmailTarget.textContent;
    } else {
      email = this.inputEmailTarget.value;
    }

    document.getElementById("email-content").textContent = email;
  }
}
